/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/

$(function() {
    $('#contact-form').on('submit', function(event) {
        event.preventDefault();

        const form = $(this);

        form.find('.has-error').removeClass('has-error');
        form.find('.alert-danger, .text-danger').remove();

        const submitButton = form.find('button');
        submitButton.attr('disabled', true);

        const honeypot = {
            name: form.find('input[name=name]').val(),
            email: form.find('input[email=email]').val(),
        };

        if (!isEmptyString(honeypot.name) || !isEmptyString(honeypot.email)) {
            // bot request
            return;
        }

        const nameInput = form.find('#l1');
        const emailInput = form.find('#l2');
        const messageInput = form.find('#l3');

        if (!validField(nameInput) || !validField(emailInput) || !validField(messageInput)) {
            submitButton.attr('disabled', false);
            return;
        }

        const data = {
            name: nameInput.val(),
            email: emailInput.val(),
            message: messageInput.val(),
        };

        $.ajax({
            url: 'https://1l0albuut4.execute-api.eu-central-1.amazonaws.com/Prod/send',
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            data: JSON.stringify(data),
            dataType: 'json',
            success: function() {
                form.prepend('<div class="alert alert-success">Your message has been sent!</div>');

                nameInput.val('');
                emailInput.val('');
                messageInput.val('');
            },
            error: function() {
                form.prepend('<div class="alert alert-danger">Something went wrong, please try again</div>');
                submitButton.attr('disabled', false);
            },
        });
    });
});

function isUndefined(value) {
    return value === undefined;
}

function isNull(value) {
    return value === null;
}

function isNullOrUndefined(value) {
    return isNull(value) || isUndefined(value);
}

function isEmptyString(value) {
    return isNull(value) || isUndefined(value) || value === '';
}

function validField(input) {
    const value = input.val();
    if (isEmptyString(value)) {
        const formGroup = input.parents('.form-group');
        formGroup.addClass('has-error');
        formGroup.prepend('<small class="form-text text-danger">This field is required</small>');

        return false;
    }

    return true;
}
